/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation preReserveChild($childNumber: Int!, $paymentIntervalId: String!) {\n    preReserveChild(\n      childNumber: $childNumber\n      paymentIntervalId: $paymentIntervalId\n    ) {\n      preReserved\n    }\n  }\n": types.PreReserveChildDocument,
    "\n  query child($fullNumber: String!) {\n    child(fullNumber: $fullNumber) {\n      name\n      number\n      fullNumber\n      mainPhoto\n      description\n      birthDate\n      sex\n      top\n      state\n      preReserved\n      motherName\n      fatherName\n      adoption {\n        id\n        user {\n          email\n        }\n      }\n      school {\n        name\n      }\n      coordinator {\n        name\n      }\n      teaser\n      attachmentGroups {\n        id\n        title\n        attachments {\n          id\n          type\n          filename\n        }\n      }\n      storage {\n        files {\n          name\n          path\n          link\n        }\n        directories {\n          name\n          files {\n            name\n            path\n            link\n          }\n        }\n      }\n    }\n  }\n": types.ChildDocument,
    "\n  query childList($state: String!) {\n    childList(state: $state) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          top\n          fullNumber\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n": types.ChildListDocument,
    "\n  query childSearch($text: String!) {\n    childSearch(text: $text) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          fullNumber\n          top\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n": types.ChildSearchDocument,
    "\n  query QueryUserInfo {\n    me {\n      username\n      email\n      phone\n      role\n    }\n  }\n": types.QueryUserInfoDocument,
    "\n  mutation Login($username: String!, $password: String!) {\n    login(data: { username: $username, password: $password }) {\n      user {\n        username\n        email\n        phone\n        role\n      }\n      accessToken\n    }\n  }\n": types.LoginDocument,
    "\n  mutation Impersonation($userId: String!) {\n    getTokenForImpersonation(userId: $userId) {\n      accessToken\n    }\n  }\n": types.ImpersonationDocument,
    "\n  query page($slug: String!) {\n    page(slug: $slug) {\n      title\n      content\n    }\n  }\n": types.PageDocument,
    "\n  query photoGallery($slug: String!) {\n    photoGallery(slug: $slug) {\n      id\n      slug\n      name\n      description\n      createdAt\n      photos {\n        id\n        description\n        filename\n        rotationAngle\n      }\n    }\n  }\n": types.PhotoGalleryDocument,
    "\n  query getAdoptionDetails($adoptionId: String!) {\n    adminAdoptionDetail(adoptionId: $adoptionId) {\n      user {\n        email\n      }\n      child {\n        name\n        fullNumber\n      }\n      paymentRequests {\n        id\n        paymentDate\n        paid\n        intervalDate {\n          paymentInterval {\n            price\n            intervalReminders {\n              id\n              daysBefore\n            }\n          }\n        }\n        reminders {\n          remindedAt\n          intervalReminder {\n            id\n            daysBefore\n          }\n        }\n      }\n      paymentInterval {\n        treaty\n      }\n    }\n  }\n": types.GetAdoptionDetailsDocument,
    "\n  mutation ChangeEmailTemplate(\n    $id: String!\n    $subject: String!\n    $body: String!\n  ) {\n    changeEmailTemplate(data: { id: $id, subject: $subject, body: $body }) {\n      id\n    }\n  }\n": types.ChangeEmailTemplateDocument,
    "\n  query EmailTemplates {\n    emailTemplates {\n      id\n      type\n      subject\n      body\n    }\n  }\n": types.EmailTemplatesDocument,
    "\n  query getUserDetail($email: String!) {\n    userDetail(email: $email) {\n      id\n      email\n      phone\n      role\n      legacyId\n      adoptions {\n        id\n        child {\n          name\n          fullNumber\n          mainPhoto\n        }\n        paymentInterval {\n          treaty\n        }\n        lastPayment\n        nextPayment\n      }\n      mailingAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n    }\n  }\n": types.GetUserDetailDocument,
    "\n  query AdminSearchUserEmail($childNumbers: String!) {\n    adminSearchUserEmail(data: $childNumbers)\n  }\n": types.AdminSearchUserEmailDocument,
    "\n  query GetUsersList($search: String) {\n    usersList(search: $search) {\n      edges {\n        node {\n          id\n          email\n          username\n          adoptions {\n            id\n            child {\n              name\n              fullNumber\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetUsersListDocument,
    "\n  mutation AttachVideoLink(\n    $childNumber: Int!\n    $groupName: String!\n    $link: String!\n  ) {\n    attachVideoLink(\n      input: {\n        childNumber: $childNumber\n        attachmentGroup: $groupName\n        link: $link\n      }\n    ) {\n      success\n      message\n    }\n  }\n": types.AttachVideoLinkDocument,
    "\n  query GetBankCertificates {\n    bankCertificates {\n      id\n      filename\n      active\n      createdAt\n      updatedAt\n    }\n  }\n": types.GetBankCertificatesDocument,
    "\n  query UsersListForTx {\n    usersList {\n      edges {\n        node {\n          id\n          mailingAddress {\n            name\n          }\n          email\n        }\n      }\n    }\n    adminChildList {\n      id\n      fullNumber\n      name\n    }\n  }\n": types.UsersListForTxDocument,
    "\n  mutation CreateOrUpdateUserTx(\n    $id: String\n    $timestamp: DateTime!\n    $type: String!\n    $userId: String\n    $childId: String\n    $value: Int!\n    $currency: String!\n    $description: String!\n    $status: String!\n  ) {\n    createOrUpdateTransaction(\n      data: {\n        id: $id\n        timestamp: $timestamp\n        type: $type\n        userId: $userId\n        childId: $childId\n        value: $value\n        currency: $currency\n        description: $description\n        status: $status\n      }\n    ) {\n      id\n    }\n  }\n": types.CreateOrUpdateUserTxDocument,
    "\n  mutation DeleteUserTransaction($id: String!) {\n    deleteTransaction(data: { id: $id }) {\n      success\n    }\n  }\n": types.DeleteUserTransactionDocument,
    "\n  query AdminAdoptionInfo($childId: String!) {\n    adminAdoptionInfo(data: $childId)\n  }\n": types.AdminAdoptionInfoDocument,
    "\n  query UserTransactions($filterByEmail: String) {\n    transactions(email: $filterByEmail) {\n      edges {\n        node {\n          id\n          timestamp\n          type\n          source\n          user {\n            id\n            username\n            email\n            mailingAddress {\n              name\n            }\n          }\n          child {\n            id\n            name\n            fullNumber\n          }\n          status\n          value\n          currency\n          description\n          bankReference\n          bankSourceAccountPrefix\n          bankSourceAccountNumber\n          bankSourceAccountCode\n          bankSourceAccountName\n          bankMessage\n          bankVariableSymbol\n          bankConstantSymbol\n        }\n      }\n    }\n  }\n": types.UserTransactionsDocument,
    "\n  mutation ChangeMyAddress(\n    $addressType: AddressType!\n    $name: String\n    $street: String\n    $city: String\n    $zip: String\n    $state: String\n    $phone: String\n    $isSameAsMailing: Boolean\n  ) {\n    changeMyAddress(\n      data: {\n        addressType: $addressType\n        name: $name\n        street: $street\n        city: $city\n        zip: $zip\n        state: $state\n        phone: $phone\n        isSameAsMailing: $isSameAsMailing\n      }\n    ) {\n      id\n      addressType\n      name\n      street\n      city\n      zip\n      state\n      phone\n      isSameAsMailing\n    }\n  }\n": types.ChangeMyAddressDocument,
    "\n  mutation ChangeAdoptionCertificateName($adoptionId: String!, $name: String!) {\n    changeAdoptionCertificateName(\n      data: { adoptionId: $adoptionId, name: $name }\n    ) {\n      success\n      message\n    }\n  }\n": types.ChangeAdoptionCertificateNameDocument,
    "\n  query MyAdoptions {\n    myAdoptions {\n      id\n      child {\n        name\n        fullNumber\n        mainPhoto\n      }\n      paymentInterval {\n        treaty\n      }\n      nextPayment\n      certificateName\n    }\n  }\n": types.MyAdoptionsDocument,
    "\n  mutation ChangePassword(\n    $newPassword: String!\n    $oldPassword: String\n    $token: String\n  ) {\n    changePassword(\n      data: {\n        newPassword: $newPassword\n        oldPassword: $oldPassword\n        token: $token\n      }\n    ) {\n      success\n      message\n    }\n  }\n": types.ChangePasswordDocument,
    "\n  mutation ResetPasswordRequest($email: String!, $captchaToken: String!) {\n    resetPasswordRequest(email: $email, captchaToken: $captchaToken) {\n      success\n      message\n    }\n  }\n": types.ResetPasswordRequestDocument,
    "\n  mutation VerifyEmailChange($token: String!) {\n    verifyEmailChange(token: $token) {\n      success\n      message\n    }\n  }\n": types.VerifyEmailChangeDocument,
    "\n  mutation CreateGiftCertificate(\n    $year: Int!\n    $name: String!\n    $company: String\n    $address: String!\n    $icRc: String\n    $dic: String\n  ) {\n    createGiftCertificate(\n      data: {\n        year: $year\n        name: $name\n        company: $company\n        address: $address\n        icRc: $icRc\n        dic: $dic\n      }\n    ) {\n      success\n      message\n    }\n  }\n": types.CreateGiftCertificateDocument,
    "\n  query MyPaymentRequests {\n    myPaymentRequests {\n      id\n      adoption {\n        child {\n          fullNumber\n        }\n        paymentInterval {\n          price\n        }\n      }\n      paid\n      paymentDate\n    }\n  }\n": types.MyPaymentRequestsDocument,
    "\n  query MyAddresses {\n    myAddresses {\n      mailingAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n        isSameAsMailing\n      }\n    }\n  }\n": types.MyAddressesDocument,
    "\n  mutation UserRegistration(\n    $email: String!\n    $password: String!\n    $phone: String\n    $captchaToken: String!\n  ) {\n    userRegistration(\n      data: { email: $email, password: $password, phone: $phone }\n      captchaToken: $captchaToken\n    ) {\n      success\n      message\n    }\n  }\n": types.UserRegistrationDocument,
    "\n  query MyTransactionsSummary {\n    myTransactionsSummary {\n      year\n      value\n      certificate {\n        id\n        downloadAllowed\n      }\n    }\n  }\n": types.MyTransactionsSummaryDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation preReserveChild($childNumber: Int!, $paymentIntervalId: String!) {\n    preReserveChild(\n      childNumber: $childNumber\n      paymentIntervalId: $paymentIntervalId\n    ) {\n      preReserved\n    }\n  }\n"): (typeof documents)["\n  mutation preReserveChild($childNumber: Int!, $paymentIntervalId: String!) {\n    preReserveChild(\n      childNumber: $childNumber\n      paymentIntervalId: $paymentIntervalId\n    ) {\n      preReserved\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query child($fullNumber: String!) {\n    child(fullNumber: $fullNumber) {\n      name\n      number\n      fullNumber\n      mainPhoto\n      description\n      birthDate\n      sex\n      top\n      state\n      preReserved\n      motherName\n      fatherName\n      adoption {\n        id\n        user {\n          email\n        }\n      }\n      school {\n        name\n      }\n      coordinator {\n        name\n      }\n      teaser\n      attachmentGroups {\n        id\n        title\n        attachments {\n          id\n          type\n          filename\n        }\n      }\n      storage {\n        files {\n          name\n          path\n          link\n        }\n        directories {\n          name\n          files {\n            name\n            path\n            link\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query child($fullNumber: String!) {\n    child(fullNumber: $fullNumber) {\n      name\n      number\n      fullNumber\n      mainPhoto\n      description\n      birthDate\n      sex\n      top\n      state\n      preReserved\n      motherName\n      fatherName\n      adoption {\n        id\n        user {\n          email\n        }\n      }\n      school {\n        name\n      }\n      coordinator {\n        name\n      }\n      teaser\n      attachmentGroups {\n        id\n        title\n        attachments {\n          id\n          type\n          filename\n        }\n      }\n      storage {\n        files {\n          name\n          path\n          link\n        }\n        directories {\n          name\n          files {\n            name\n            path\n            link\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query childList($state: String!) {\n    childList(state: $state) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          top\n          fullNumber\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query childList($state: String!) {\n    childList(state: $state) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          top\n          fullNumber\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query childSearch($text: String!) {\n    childSearch(text: $text) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          fullNumber\n          top\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query childSearch($text: String!) {\n    childSearch(text: $text) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          fullNumber\n          top\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query QueryUserInfo {\n    me {\n      username\n      email\n      phone\n      role\n    }\n  }\n"): (typeof documents)["\n  query QueryUserInfo {\n    me {\n      username\n      email\n      phone\n      role\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Login($username: String!, $password: String!) {\n    login(data: { username: $username, password: $password }) {\n      user {\n        username\n        email\n        phone\n        role\n      }\n      accessToken\n    }\n  }\n"): (typeof documents)["\n  mutation Login($username: String!, $password: String!) {\n    login(data: { username: $username, password: $password }) {\n      user {\n        username\n        email\n        phone\n        role\n      }\n      accessToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Impersonation($userId: String!) {\n    getTokenForImpersonation(userId: $userId) {\n      accessToken\n    }\n  }\n"): (typeof documents)["\n  mutation Impersonation($userId: String!) {\n    getTokenForImpersonation(userId: $userId) {\n      accessToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query page($slug: String!) {\n    page(slug: $slug) {\n      title\n      content\n    }\n  }\n"): (typeof documents)["\n  query page($slug: String!) {\n    page(slug: $slug) {\n      title\n      content\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query photoGallery($slug: String!) {\n    photoGallery(slug: $slug) {\n      id\n      slug\n      name\n      description\n      createdAt\n      photos {\n        id\n        description\n        filename\n        rotationAngle\n      }\n    }\n  }\n"): (typeof documents)["\n  query photoGallery($slug: String!) {\n    photoGallery(slug: $slug) {\n      id\n      slug\n      name\n      description\n      createdAt\n      photos {\n        id\n        description\n        filename\n        rotationAngle\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAdoptionDetails($adoptionId: String!) {\n    adminAdoptionDetail(adoptionId: $adoptionId) {\n      user {\n        email\n      }\n      child {\n        name\n        fullNumber\n      }\n      paymentRequests {\n        id\n        paymentDate\n        paid\n        intervalDate {\n          paymentInterval {\n            price\n            intervalReminders {\n              id\n              daysBefore\n            }\n          }\n        }\n        reminders {\n          remindedAt\n          intervalReminder {\n            id\n            daysBefore\n          }\n        }\n      }\n      paymentInterval {\n        treaty\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAdoptionDetails($adoptionId: String!) {\n    adminAdoptionDetail(adoptionId: $adoptionId) {\n      user {\n        email\n      }\n      child {\n        name\n        fullNumber\n      }\n      paymentRequests {\n        id\n        paymentDate\n        paid\n        intervalDate {\n          paymentInterval {\n            price\n            intervalReminders {\n              id\n              daysBefore\n            }\n          }\n        }\n        reminders {\n          remindedAt\n          intervalReminder {\n            id\n            daysBefore\n          }\n        }\n      }\n      paymentInterval {\n        treaty\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangeEmailTemplate(\n    $id: String!\n    $subject: String!\n    $body: String!\n  ) {\n    changeEmailTemplate(data: { id: $id, subject: $subject, body: $body }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation ChangeEmailTemplate(\n    $id: String!\n    $subject: String!\n    $body: String!\n  ) {\n    changeEmailTemplate(data: { id: $id, subject: $subject, body: $body }) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EmailTemplates {\n    emailTemplates {\n      id\n      type\n      subject\n      body\n    }\n  }\n"): (typeof documents)["\n  query EmailTemplates {\n    emailTemplates {\n      id\n      type\n      subject\n      body\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getUserDetail($email: String!) {\n    userDetail(email: $email) {\n      id\n      email\n      phone\n      role\n      legacyId\n      adoptions {\n        id\n        child {\n          name\n          fullNumber\n          mainPhoto\n        }\n        paymentInterval {\n          treaty\n        }\n        lastPayment\n        nextPayment\n      }\n      mailingAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n    }\n  }\n"): (typeof documents)["\n  query getUserDetail($email: String!) {\n    userDetail(email: $email) {\n      id\n      email\n      phone\n      role\n      legacyId\n      adoptions {\n        id\n        child {\n          name\n          fullNumber\n          mainPhoto\n        }\n        paymentInterval {\n          treaty\n        }\n        lastPayment\n        nextPayment\n      }\n      mailingAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminSearchUserEmail($childNumbers: String!) {\n    adminSearchUserEmail(data: $childNumbers)\n  }\n"): (typeof documents)["\n  query AdminSearchUserEmail($childNumbers: String!) {\n    adminSearchUserEmail(data: $childNumbers)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUsersList($search: String) {\n    usersList(search: $search) {\n      edges {\n        node {\n          id\n          email\n          username\n          adoptions {\n            id\n            child {\n              name\n              fullNumber\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUsersList($search: String) {\n    usersList(search: $search) {\n      edges {\n        node {\n          id\n          email\n          username\n          adoptions {\n            id\n            child {\n              name\n              fullNumber\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AttachVideoLink(\n    $childNumber: Int!\n    $groupName: String!\n    $link: String!\n  ) {\n    attachVideoLink(\n      input: {\n        childNumber: $childNumber\n        attachmentGroup: $groupName\n        link: $link\n      }\n    ) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation AttachVideoLink(\n    $childNumber: Int!\n    $groupName: String!\n    $link: String!\n  ) {\n    attachVideoLink(\n      input: {\n        childNumber: $childNumber\n        attachmentGroup: $groupName\n        link: $link\n      }\n    ) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBankCertificates {\n    bankCertificates {\n      id\n      filename\n      active\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query GetBankCertificates {\n    bankCertificates {\n      id\n      filename\n      active\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UsersListForTx {\n    usersList {\n      edges {\n        node {\n          id\n          mailingAddress {\n            name\n          }\n          email\n        }\n      }\n    }\n    adminChildList {\n      id\n      fullNumber\n      name\n    }\n  }\n"): (typeof documents)["\n  query UsersListForTx {\n    usersList {\n      edges {\n        node {\n          id\n          mailingAddress {\n            name\n          }\n          email\n        }\n      }\n    }\n    adminChildList {\n      id\n      fullNumber\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateOrUpdateUserTx(\n    $id: String\n    $timestamp: DateTime!\n    $type: String!\n    $userId: String\n    $childId: String\n    $value: Int!\n    $currency: String!\n    $description: String!\n    $status: String!\n  ) {\n    createOrUpdateTransaction(\n      data: {\n        id: $id\n        timestamp: $timestamp\n        type: $type\n        userId: $userId\n        childId: $childId\n        value: $value\n        currency: $currency\n        description: $description\n        status: $status\n      }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOrUpdateUserTx(\n    $id: String\n    $timestamp: DateTime!\n    $type: String!\n    $userId: String\n    $childId: String\n    $value: Int!\n    $currency: String!\n    $description: String!\n    $status: String!\n  ) {\n    createOrUpdateTransaction(\n      data: {\n        id: $id\n        timestamp: $timestamp\n        type: $type\n        userId: $userId\n        childId: $childId\n        value: $value\n        currency: $currency\n        description: $description\n        status: $status\n      }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteUserTransaction($id: String!) {\n    deleteTransaction(data: { id: $id }) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteUserTransaction($id: String!) {\n    deleteTransaction(data: { id: $id }) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminAdoptionInfo($childId: String!) {\n    adminAdoptionInfo(data: $childId)\n  }\n"): (typeof documents)["\n  query AdminAdoptionInfo($childId: String!) {\n    adminAdoptionInfo(data: $childId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UserTransactions($filterByEmail: String) {\n    transactions(email: $filterByEmail) {\n      edges {\n        node {\n          id\n          timestamp\n          type\n          source\n          user {\n            id\n            username\n            email\n            mailingAddress {\n              name\n            }\n          }\n          child {\n            id\n            name\n            fullNumber\n          }\n          status\n          value\n          currency\n          description\n          bankReference\n          bankSourceAccountPrefix\n          bankSourceAccountNumber\n          bankSourceAccountCode\n          bankSourceAccountName\n          bankMessage\n          bankVariableSymbol\n          bankConstantSymbol\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserTransactions($filterByEmail: String) {\n    transactions(email: $filterByEmail) {\n      edges {\n        node {\n          id\n          timestamp\n          type\n          source\n          user {\n            id\n            username\n            email\n            mailingAddress {\n              name\n            }\n          }\n          child {\n            id\n            name\n            fullNumber\n          }\n          status\n          value\n          currency\n          description\n          bankReference\n          bankSourceAccountPrefix\n          bankSourceAccountNumber\n          bankSourceAccountCode\n          bankSourceAccountName\n          bankMessage\n          bankVariableSymbol\n          bankConstantSymbol\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangeMyAddress(\n    $addressType: AddressType!\n    $name: String\n    $street: String\n    $city: String\n    $zip: String\n    $state: String\n    $phone: String\n    $isSameAsMailing: Boolean\n  ) {\n    changeMyAddress(\n      data: {\n        addressType: $addressType\n        name: $name\n        street: $street\n        city: $city\n        zip: $zip\n        state: $state\n        phone: $phone\n        isSameAsMailing: $isSameAsMailing\n      }\n    ) {\n      id\n      addressType\n      name\n      street\n      city\n      zip\n      state\n      phone\n      isSameAsMailing\n    }\n  }\n"): (typeof documents)["\n  mutation ChangeMyAddress(\n    $addressType: AddressType!\n    $name: String\n    $street: String\n    $city: String\n    $zip: String\n    $state: String\n    $phone: String\n    $isSameAsMailing: Boolean\n  ) {\n    changeMyAddress(\n      data: {\n        addressType: $addressType\n        name: $name\n        street: $street\n        city: $city\n        zip: $zip\n        state: $state\n        phone: $phone\n        isSameAsMailing: $isSameAsMailing\n      }\n    ) {\n      id\n      addressType\n      name\n      street\n      city\n      zip\n      state\n      phone\n      isSameAsMailing\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangeAdoptionCertificateName($adoptionId: String!, $name: String!) {\n    changeAdoptionCertificateName(\n      data: { adoptionId: $adoptionId, name: $name }\n    ) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation ChangeAdoptionCertificateName($adoptionId: String!, $name: String!) {\n    changeAdoptionCertificateName(\n      data: { adoptionId: $adoptionId, name: $name }\n    ) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyAdoptions {\n    myAdoptions {\n      id\n      child {\n        name\n        fullNumber\n        mainPhoto\n      }\n      paymentInterval {\n        treaty\n      }\n      nextPayment\n      certificateName\n    }\n  }\n"): (typeof documents)["\n  query MyAdoptions {\n    myAdoptions {\n      id\n      child {\n        name\n        fullNumber\n        mainPhoto\n      }\n      paymentInterval {\n        treaty\n      }\n      nextPayment\n      certificateName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangePassword(\n    $newPassword: String!\n    $oldPassword: String\n    $token: String\n  ) {\n    changePassword(\n      data: {\n        newPassword: $newPassword\n        oldPassword: $oldPassword\n        token: $token\n      }\n    ) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation ChangePassword(\n    $newPassword: String!\n    $oldPassword: String\n    $token: String\n  ) {\n    changePassword(\n      data: {\n        newPassword: $newPassword\n        oldPassword: $oldPassword\n        token: $token\n      }\n    ) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ResetPasswordRequest($email: String!, $captchaToken: String!) {\n    resetPasswordRequest(email: $email, captchaToken: $captchaToken) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation ResetPasswordRequest($email: String!, $captchaToken: String!) {\n    resetPasswordRequest(email: $email, captchaToken: $captchaToken) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation VerifyEmailChange($token: String!) {\n    verifyEmailChange(token: $token) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation VerifyEmailChange($token: String!) {\n    verifyEmailChange(token: $token) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateGiftCertificate(\n    $year: Int!\n    $name: String!\n    $company: String\n    $address: String!\n    $icRc: String\n    $dic: String\n  ) {\n    createGiftCertificate(\n      data: {\n        year: $year\n        name: $name\n        company: $company\n        address: $address\n        icRc: $icRc\n        dic: $dic\n      }\n    ) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation CreateGiftCertificate(\n    $year: Int!\n    $name: String!\n    $company: String\n    $address: String!\n    $icRc: String\n    $dic: String\n  ) {\n    createGiftCertificate(\n      data: {\n        year: $year\n        name: $name\n        company: $company\n        address: $address\n        icRc: $icRc\n        dic: $dic\n      }\n    ) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyPaymentRequests {\n    myPaymentRequests {\n      id\n      adoption {\n        child {\n          fullNumber\n        }\n        paymentInterval {\n          price\n        }\n      }\n      paid\n      paymentDate\n    }\n  }\n"): (typeof documents)["\n  query MyPaymentRequests {\n    myPaymentRequests {\n      id\n      adoption {\n        child {\n          fullNumber\n        }\n        paymentInterval {\n          price\n        }\n      }\n      paid\n      paymentDate\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyAddresses {\n    myAddresses {\n      mailingAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n        isSameAsMailing\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyAddresses {\n    myAddresses {\n      mailingAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n        isSameAsMailing\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UserRegistration(\n    $email: String!\n    $password: String!\n    $phone: String\n    $captchaToken: String!\n  ) {\n    userRegistration(\n      data: { email: $email, password: $password, phone: $phone }\n      captchaToken: $captchaToken\n    ) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation UserRegistration(\n    $email: String!\n    $password: String!\n    $phone: String\n    $captchaToken: String!\n  ) {\n    userRegistration(\n      data: { email: $email, password: $password, phone: $phone }\n      captchaToken: $captchaToken\n    ) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyTransactionsSummary {\n    myTransactionsSummary {\n      year\n      value\n      certificate {\n        id\n        downloadAllowed\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyTransactionsSummary {\n    myTransactionsSummary {\n      year\n      value\n      certificate {\n        id\n        downloadAllowed\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;