import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

const MENU_TREE_QUERY = gql`
  query {
    menuTree(slug: "hlavni-menu") {
      title
      id
      directUrl
      page {
        slug
      }
      children {
        title
        id
        directUrl
        page {
          slug
        }
      }
    }
  }
`;

export interface IMenuItem {
  title: string;
  id: string;
  directUrl: string;
  highlighted?: boolean;
  children: IMenuItem[];
  page: {
    slug: string;
  };
}

interface IMenuTreeResponse {
  menuTree: IMenuItem[];
}

export function MainMenuItem(props: { data: IMenuItem; dropdown: boolean }) {
  let LinkComponent = Nav.Link;
  if (props.dropdown) {
    LinkComponent = NavDropdown.Item;
  }
  return (
    <>
      {!props.dropdown && props.data.page && (
        <LinkComponent href={'/page/' + props.data.page?.slug}>
          {props.data.title}
        </LinkComponent>
      )}
      {!props.dropdown && props.data.directUrl && (
        <LinkComponent
          className={
            props.data.highlighted ? 'bg-warning rounded text-dark' : ''
          }
          href={props.data.directUrl}
        >
          {props.data.title}
        </LinkComponent>
      )}
      {props.dropdown && (
        <NavDropdown
          title={props.data.title}
          style={{ backgroundColor: 'rgb(0,86,0)' }}
        >
          {props.data.children &&
            props.data.children.map((subitem) => (
              <MainMenuItem
                key={subitem.id}
                data={subitem}
                dropdown={false}
              ></MainMenuItem>
            ))}
        </NavDropdown>
      )}
    </>
  );
}

export default function NavigationBarContent() {
  const { loading, error, data } = useQuery<IMenuTreeResponse>(MENU_TREE_QUERY);

  if (loading || data === null) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba : {error.message}</p>;

  const menuTree = data?.menuTree;

  return (
    <>
      {menuTree &&
        menuTree.map((subitem) => (
          <MainMenuItem
            key={subitem.id}
            data={subitem}
            dropdown={subitem.children.length > 0}
          ></MainMenuItem>
        ))}
      <MainMenuItem
        data={
          {
            id: 'support-us-link',
            title: 'Podpořte nás',
            directUrl: '/support-us',
            highlighted: true,
          } as IMenuItem
        }
        dropdown={false}
      ></MainMenuItem>
    </>
  );
}
