import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { preparePhotoUrl } from '../tools';

const GET_SUPPORTER_LIST = gql`
  query {
    supporters {
      id
      name
      description
      logo
      link
    }
  }
`;

interface ISupporter {
  id: string;
  name: string;
  description: string;
  logo: string;
  link: string;
}

export function SupportedBy() {
  const { loading, error, data } = useQuery(GET_SUPPORTER_LIST);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const supporters = data.supporters;

  if (supporters === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  return (
    <div className="page-detail">
      <h5>Podporují nás</h5>
      <div className="page-content">
        <p>
          Děkujeme těmto jednotlivcům, firmám a organizacím, kteří mají na svých
          stránkách umístěn banner či jiný odkaz na naše stránky{' '}
          <a href="/">https://www.pro-contact.cz</a> a pomáhají tak dalším dětem
          z Guineje nalézt své adoptivní rodiče.
        </p>
        <div className="d-flex flex-wrap">
          {supporters.map((supporter: ISupporter) => (
            <div
              className="m-2 p-2"
              key={supporter.id}
              style={{
                backgroundColor: '#ac2800',
                border: '1px solid white',
              }}
            >
              <h6>
                <a href={supporter.name}>{supporter.name}</a>
              </h6>
              {supporter.logo && (
                <img src={preparePhotoUrl(supporter.logo)}></img>
              )}
              <p
                className="align-top"
                dangerouslySetInnerHTML={{
                  __html: supporter.description,
                }}
              ></p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
